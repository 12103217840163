import React, { useState } from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Button, Card, HelperText, Title, Text } from 'react-native-paper';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { TextInput } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import i18n from 'i18n-js';

import { globalStyles } from '../../constants/Styles';
import { AppDispatch } from '../../redux/store';
import { passwordEmail, clearRequests } from '../../redux/authSlice';
import { RootState } from '../../redux/rootReducer';
import { logoImg } from '../../constants/Images';

export const PasswordEmailScreen = ({
  navigation,
}: {
  navigation: StackNavigationProp<ParamListBase>;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { requestSuccess, error, loading } = useSelector(
    (state: RootState) => state.auth,
  );

  const [email, setEmail] = useState('');
  const emailError = get(error, 'errors.email[0]', false);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(clearRequests());
    });

    // componentDidUpdate
    if (requestSuccess) {
      navigation.navigate('EmailSentScreen');
    }
    return unsubscribe;
  }, [dispatch, navigation, requestSuccess]);

  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.basicScrollContent}>
        <Image source={logoImg} style={[globalStyles.logo]} />
        <Card style={globalStyles.centerCard}>
          <Card.Content>
            <Title>{i18n.t('reset_password')}</Title>
            <Text style={styles.text}>
              {i18n.t('reset_password_description')}
            </Text>
            <TextInput
              label={i18n.t('email')}
              autoCompleteType="email"
              autoCapitalize="none"
              mode="outlined"
              value={email}
              onChangeText={(text) => setEmail(text)}
            />

            {emailError && (
              <HelperText type="error" visible={emailError}>
                {emailError}
              </HelperText>
            )}

            <Button
              mode="contained"
              loading={loading}
              onPress={() => dispatch(passwordEmail({ email }))}
              style={styles.button}>
              {i18n.t('send_me_link')}
            </Button>
            <Button
              style={styles.button}
              mode="text"
              onPress={() => navigation.replace('LogInScreen')}>
              {i18n.t('back_to_login')}
            </Button>
          </Card.Content>
        </Card>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    marginVertical: 10,
  },
  button: {
    marginTop: 15,
  },
});
