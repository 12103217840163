import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { HomeStackParamList, RootStackParamList } from '../types';
import { PostDetailsScreen } from '../screens/posts/PostDetailsScreen';
import DrawerNavigator from './DrawerNavigator';
import { Appbar, useTheme } from 'react-native-paper';
import { StackNavigationProp } from '@react-navigation/stack';
import ProfileEditScreen from '../screens/profile/ProfileEditScreen';
import i18n from 'i18n-js';
import { Platform } from 'react-native';
import PasswordEditScreen from '../screens/profile/PasswordEdit';
import { useLinkTo, useNavigation } from '@react-navigation/native';

const Stack = createStackNavigator<HomeStackParamList>();

type ProfileScreenNavigationProp = StackNavigationProp<
  RootStackParamList,
  'HomeStack'
>;

type Props = {
  navigation: ProfileScreenNavigationProp;
};

const AppBarBack = ({ webUrl }: { webUrl: string }) => {
  const linkTo = useLinkTo();
  const navigation = useNavigation();

  return (
    <Appbar.Action
      color="white"
      icon="arrow-left"
      onPress={() =>
        Platform.OS === 'android' || Platform.OS === 'ios'
          ? navigation.goBack()
          : linkTo(webUrl)
      }
    />
  );
};

export default function HomeStackNavigator({ navigation }: Props) {
  const { colors } = useTheme();

  const defaultStackHeaderOptions: any = {
    headerTitleAlign: 'center',
    headerTintColor: '#fff',
    headerStyle: { backgroundColor: colors.primary },
    headerLeft: () => <AppBarBack webUrl="/home" />,
  };

  return (
    <Stack.Navigator
      initialRouteName="HomeDrawer"
      screenOptions={defaultStackHeaderOptions}>
      <Stack.Screen
        name="HomeDrawer"
        component={DrawerNavigator}
        options={{ headerShown: false, transitionSpec: undefined }}
      />

      <Stack.Screen
        name="PostDetails"
        component={PostDetailsScreen}
        options={({ route }) => ({ title: route.params.postTitle })}
      />
      <Stack.Screen
        name="ProfileEdit"
        component={ProfileEditScreen}
        options={{
          title: i18n.t('edit_profile'),
          headerLeft: () => <AppBarBack webUrl="/profile" />,
        }}
      />
      <Stack.Screen
        name="PasswordEdit"
        component={PasswordEditScreen}
        options={{
          title: i18n.t('edit_password'),
          headerLeft: () => <AppBarBack webUrl="/profile/edit" />,
        }}
      />
    </Stack.Navigator>
  );
}
