import * as React from 'react';
import { View, Image, StyleSheet } from 'react-native';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
  DrawerContentComponentProps,
  DrawerContentOptions,
} from '@react-navigation/drawer';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../redux/store';
import { RootState } from '../redux/rootReducer';
import {
  Avatar,
  Subheading,
  Title,
  useTheme,
  Appbar,
  TouchableRipple,
} from 'react-native-paper';
import { getMyUser, logout } from '../redux/authSlice';
import { logoImg } from '../constants/Images';
import { globalStyles } from '../constants/Styles';
import { SettingsScreen } from '../screens/drawer/SettingsScreen';
import { NotificationsScreen } from '../screens/drawer/NotificationsScreen';
import { DrawerActions } from '@react-navigation/native';
import { HomeDrawerParamList } from '../types';
import { PostListScreen } from '../screens/posts/PostListScreen';
import ProfileScreen from '../screens/profile/ProfileScreen';
import i18n from 'i18n-js';

function CustomDrawerContent(
  props: DrawerContentComponentProps<DrawerContentOptions>,
) {
  const dispatch: AppDispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const { colors } = useTheme();

  const { state, ...rest } = props;
  const newState = { ...state }; //copy from state before applying any filter. do not change original state
  newState.routes = newState.routes.filter((item) => item.name !== 'Profile'); //replace "Login' with your route name

  const navigation = useNavigation();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(getMyUser({}));
    });
    return unsubscribe;
  }, [dispatch, navigation]);
  return (
    <View style={globalStyles.flex}>
      <TouchableRipple
        onPress={() => props.navigation.navigate('Profile')}
        style={[
          {
            backgroundColor: colors.primary,
          },
          styles.drawerTopContainer,
        ]}>
        <>
          {user.thumbnail ? (
            <Avatar.Image {...props} source={{ uri: user.thumbnail }} />
          ) : (
            <Avatar.Icon {...props} icon="account" />
          )}

          <Title
            style={
              styles.title
            }>{`${user.first_name} ${user.last_name}`}</Title>
          <Subheading style={styles.subheading}>{user.email}</Subheading>
        </>
      </TouchableRipple>
      <DrawerContentScrollView {...props}>
        <DrawerItemList state={newState} {...rest} />
      </DrawerContentScrollView>
      <DrawerItem
        label={i18n.t('logout')}
        onPress={() => dispatch(logout())}
        icon={({ size, color }) => (
          <Icon size={size} color={color} name="logout" />
        )}
      />
    </View>
  );
}

const Drawer = createDrawerNavigator<HomeDrawerParamList>();

export default function DrawerNavigator() {
  const { colors } = useTheme();

  const navigation = useNavigation();
  const defaultDrawerHeaderOptions: any = {
    headerTitleAlign: 'center',
    headerTintColor: '#fff',
    headerStyle: { backgroundColor: colors.primary },
    headerLeft: () => (
      <Appbar.Action
        color="white"
        icon="menu"
        onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}
      />
    ),
  };

  return (
    <Drawer.Navigator
      initialRouteName="Home"
      drawerContent={(props) => <CustomDrawerContent {...props} />}
      screenOptions={{ headerShown: true, ...defaultDrawerHeaderOptions }}>
      <Drawer.Screen
        name="Home"
        component={PostListScreen}
        options={{
          title: i18n.t('home'),
          headerTitle: () => (
            <Image source={logoImg} style={styles.logoHeader} />
          ),
          drawerIcon: ({ size, color }) => (
            <Icon size={size} color={color} name="home" />
          ),
        }}
      />
      <Drawer.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          title: i18n.t('notifications'),
          drawerIcon: ({ size, color }) => (
            <Icon size={size} color={color} name="tooltip-text" />
          ),
        }}
      />
      <Drawer.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          title: i18n.t('settings'),
          drawerIcon: ({ size, color }) => (
            <Icon size={size} color={color} name="cog" />
          ),
        }}
      />
      <Drawer.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: i18n.t('profile'),
          headerRight: () => (
            <Appbar.Action
              color="white"
              icon="pen"
              onPress={() => navigation.navigate('ProfileEdit')}
            />
          ),
        }}
      />
    </Drawer.Navigator>
  );
}

const styles = StyleSheet.create({
  logoHeader: {
    height: 30,
    width: 140,
    resizeMode: 'contain',
  },
  drawerTopContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 30,
  },
  title: {
    marginTop: 15,
    color: '#fff',
  },
  subheading: {
    color: '#fff',
  },
  editIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});
