import React, { Ref, useState } from 'react';
import { Image, StyleSheet, ScrollView, View } from 'react-native';
import { Button, Card, HelperText, Title } from 'react-native-paper';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { TextInput } from 'react-native-paper';
import { globalStyles } from '../../constants/Styles';
import { AppDispatch } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { clearRequests, register } from '../../redux/authSlice';
import { RootState } from '../../redux/rootReducer';
import { get } from 'lodash';
import { logoImg } from '../../constants/Images';
import i18n from 'i18n-js';

// import { SocialLoginBar } from './SocialLoginBar';

export const RegisterScreen = ({
  navigation,
}: {
  navigation: StackNavigationProp<ParamListBase>;
}) => {
  let passwordRef: any;
  let firstNameRef: any;
  let lastNameRef: any;

  const dispatch: AppDispatch = useDispatch();
  const { error, loading } = useSelector((state: RootState) => state.auth);

  const [email, setEmail] = useState('');
  const emailError = get(error, 'errors.email[0]', false);

  const [password, setPassword] = useState('');
  const passwordError = get(error, 'errors.password[0]', false);

  const [firstName, setFirstName] = useState('');
  const firstNameError = get(error, 'errors.first_name[0]', false);

  const [lastName, setLastName] = useState('');
  const lastNameError = get(error, 'errors.last_name[0]', false);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(clearRequests());
    });

    return unsubscribe;
  }, [dispatch, navigation]);

  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.basicScrollContent}>
        <Image source={logoImg} style={globalStyles.logo} />
        <Card style={globalStyles.centerCard}>
          <Card.Content>
            <Title style={styles.title}>{i18n.t('create_account')}</Title>
            <TextInput
              autoCapitalize={'none'}
              label={i18n.t('email')}
              autoCompleteType="email"
              textContentType="emailAddress"
              mode="outlined"
              dense={true}
              value={email}
              onChangeText={(text) => setEmail(text)}
              blurOnSubmit={false}
              onSubmitEditing={() => {
                passwordRef.focus();
              }}
            />
            {emailError && (
              <HelperText type="error" visible={emailError}>
                {emailError}
              </HelperText>
            )}
            <TextInput
              label={i18n.t('password')}
              autoCompleteType="password"
              textContentType="password"
              secureTextEntry
              mode="outlined"
              dense={true}
              value={password}
              onChangeText={(text) => setPassword(text)}
              ref={(input) => {
                passwordRef = input;
              }}
              blurOnSubmit={false}
              onSubmitEditing={() => {
                firstNameRef.focus();
              }}
            />
            {passwordError && (
              <HelperText type="error" visible={passwordError}>
                {passwordError}
              </HelperText>
            )}
            <TextInput
              label={i18n.t('first_name')}
              autoCompleteType="name"
              textContentType="givenName"
              mode="outlined"
              dense={true}
              value={firstName}
              onChangeText={(text) => setFirstName(text)}
              ref={(input) => {
                firstNameRef = input;
              }}
              blurOnSubmit={false}
              onSubmitEditing={() => {
                lastNameRef.focus();
              }}
            />
            {firstNameError && (
              <HelperText type="error" visible={firstNameError}>
                {firstNameError}
              </HelperText>
            )}
            <TextInput
              label={i18n.t('last_name')}
              autoCompleteType="name"
              textContentType="familyName"
              mode="outlined"
              dense={true}
              value={lastName}
              onChangeText={(text) => setLastName(text)}
              ref={(input) => {
                lastNameRef = input;
              }}
            />
            {lastNameError && (
              <HelperText type="error" visible={lastNameError}>
                {lastNameError}
              </HelperText>
            )}

            <Button
              style={styles.button}
              mode="contained"
              loading={loading}
              onPress={() =>
                dispatch(
                  register({
                    email,
                    password,
                    first_name: firstName,
                    last_name: lastName,
                  }),
                )
              }>
              Create
            </Button>
            <Button
              style={styles.button}
              mode="text"
              onPress={() => navigation.replace('LogInScreen')}>
              {i18n.t('back_to_login')}
            </Button>
            {/* <Title>Sign up with</Title> */}
            {/* <SocialLoginBar color={globalColors.orange} /> */}
          </Card.Content>
        </Card>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
  },
});
