import React from 'react';
import { View, StyleSheet } from 'react-native';
import { ToggleButton, useTheme, Text } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage, setTheme } from '../redux/appSlice';
import { RootState } from '../redux/rootReducer';
import { AppDispatch } from '../redux/store';

export const MiniSettingsBar = () => {
  const dispatch: AppDispatch = useDispatch();
  const { theme, language } = useSelector((state: RootState) => state.app);
  const paperTheme = useTheme();

  return (
    <View style={styles.content}>
      <ToggleButton.Row
        onValueChange={(value) => value && dispatch(setTheme(value))}
        value={theme}>
        <ToggleButton
          icon={() => (
            <Icon color={paperTheme.colors.text} name="white-balance-sunny" />
          )}
          value="light"
        />
        <ToggleButton
          icon={() => (
            <Icon color={paperTheme.colors.text} name="moon-waning-crescent" />
          )}
          value="dark"
        />
      </ToggleButton.Row>

      <ToggleButton.Row
        onValueChange={(value) => value && dispatch(setLanguage(value))}
        value={language}>
        <ToggleButton icon={() => <Text>EN</Text>} value="en" />
        <ToggleButton icon={() => <Text>FR</Text>} value="fr" />
      </ToggleButton.Row>
    </View>
  );
};

export const styles = StyleSheet.create({
  content: {
    marginHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
