export const dev = {
  API_URL: 'https://nowhere.alphanova.sg/api',
};

export const staging = {
  API_URL: 'https://nowhere.alphanova.sg/api',
};

export const production = {
  // API_URL: 'https://app.treasure-earth.global/api',
  API_URL: 'https://nowhere.alphanova.sg/api',
};

export type Environment = 'dev' | 'staging' | 'production';

const env = __DEV__ ? staging : production;

export default env;
