import { createSlice } from '@reduxjs/toolkit';
import { refreshLogin } from './authSlice';
interface AppState {
  loading: boolean;
  theme: string;
  language: string;
}

const initialState: AppState = {
  loading: true,
  theme: 'light',
  language: 'en',
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setTheme(state, action) {
      const theme = action.payload;
      state.theme = theme;
      return state;
    },
    setLanguage(state, action) {
      const language = action.payload;
      state.language = language;
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(refreshLogin.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(refreshLogin.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setTheme, setLanguage } = appSlice.actions;

export default appSlice.reducer;
