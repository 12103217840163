import * as Linking from 'expo-linking';

export default {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Auth: {
        screens: {
          LogInScreen: 'login',
          RegisterScreen: 'register',
          PasswordEmailScreen: 'password_email', // 'forgot_password'
          EmailSentScreen: 'email_sent',
        },
      },
      HomeStack: {
        screens: {
          HomeDrawer: {
            screens: {
              Home: 'home',
              Notifications: 'notifications',
              Settings: 'settings',
              Profile: 'profile',
            },
          },
          PostDetails: 'posts',
          ProfileEdit: 'profile/edit',
          PasswordEdit: 'profile/edit/password',
        },
      },
      NotFound: '*',
    },
  },
};
