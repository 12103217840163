import React from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';
import { Title, Card, Avatar, Caption } from 'react-native-paper';
import { globalStyles } from '../../constants/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { HomeDrawerParamList, HomeStackParamList } from '../../types';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { CompositeNavigationProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { AppDispatch } from '../../redux/store';
import { getMyUser } from '../../redux/authSlice';

type ProfileScreenNavigationProp = CompositeNavigationProp<
  DrawerNavigationProp<HomeDrawerParamList, 'Profile'>,
  StackNavigationProp<HomeStackParamList>
>;

export default function ProfileScreen({
  navigation,
}: {
  navigation: ProfileScreenNavigationProp;
}) {
  const dispatch: AppDispatch = useDispatch();

  const { user } = useSelector((state: RootState) => state.auth);
  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(getMyUser({}));
    });
    return unsubscribe;
  }, [dispatch, navigation]);
  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.scrollContentForNavBar}>
        <Card style={globalStyles.profileCard}>
          <Card.Content>
            {user.thumbnail ? (
              <Avatar.Image
                size={100}
                style={styles.avatar}
                source={{ uri: user.thumbnail }}
              />
            ) : (
              <Avatar.Icon size={100} style={styles.avatar} icon="account" />
            )}
            <Title style={globalStyles.textCenter}>
              {`${user.first_name} ${user.last_name}`}
            </Title>
            <Caption style={globalStyles.textCenter}>{user.email}</Caption>
          </Card.Content>
        </Card>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  avatar: {
    marginTop: -70,
    alignSelf: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 1.41,
    elevation: 2,
    marginBottom: 15,
  },
});
