import React, { Ref, useState } from 'react';
import { View, Image, StyleSheet, ScrollView } from 'react-native';
import {
  Button,
  HelperText,
  Title,
  Snackbar,
  Card,
  useTheme,
} from 'react-native-paper';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { TextInput } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { login, clearRequests, switchEnvironment } from '../../redux/authSlice';
import { RootState } from '../../redux/rootReducer';
import { AppDispatch } from '../../redux/store';
import { get } from 'lodash';
import i18n from 'i18n-js';
// import { SocialLoginBar } from './SocialLoginBar';
import { expo } from '../../app.json';
import { globalStyles } from '../../constants/Styles';
import { logoImg } from '../../constants/Images';
import { MiniSettingsBar } from '../../components/MiniSettingsBar';
import { SafeAreaView } from 'react-native-safe-area-context';

export const LogInScreen = ({
  navigation,
}: {
  navigation: StackNavigationProp<ParamListBase>;
}) => {
  let passwordRef: any;

  const dispatch: AppDispatch = useDispatch();
  const { error, loading, appEnv } = useSelector(
    (state: RootState) => state.auth,
  );
  const { colors } = useTheme();
  const [email, setEmail] = useState('');
  const emailError = get(error, 'errors.email[0]', false);

  const [password, setPassword] = useState('');
  const passwordError = get(error, 'errors.password[0]', false);

  const [snackBarVisible, setSnackBarVisible] = useState(false);

  let authError = get(error, 'error', false);
  if (authError === 'Unauthorised') {
    authError =
      'Email and/or password have been rejected. Please check your credentials and try again.';
  }

  const loginPressed = () => {
    if (password === 'cc') {
      // if (password === 'Alphan00dle') {
      if (email.trim() === 'switch_dev') {
        setSnackBarVisible(true);
        setEmail('');
        setPassword('');
        dispatch(switchEnvironment({ env: 'dev' }));
        return;
      }
      if (email.trim() === 'switch_staging') {
        setSnackBarVisible(true);
        setEmail('');
        setPassword('');
        dispatch(switchEnvironment({ env: 'staging' }));
        return;
      }
      if (email.trim() === 'switch_prod') {
        setSnackBarVisible(true);
        setEmail('');
        setPassword('');
        dispatch(switchEnvironment({ env: 'production' }));
        return;
      }
    }

    dispatch(login({ email, password }));
  };

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(clearRequests());
    });
    return unsubscribe;
  }, [dispatch, navigation]);

  return (
    // <SafeAreaView style={[globalStyles.basicContent]}>
    <SafeAreaView style={globalStyles.flex}>
      <MiniSettingsBar />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.basicScrollContent}>
        <Image source={logoImg} style={[globalStyles.logo]} />
        <Card style={globalStyles.centerCard}>
          <Card.Content>
            <Title style={styles.title}>{i18n.t('login')}</Title>
            <TextInput
              label={i18n.t('email')}
              autoCompleteType="email"
              textContentType="emailAddress"
              autoCapitalize="none"
              mode="outlined"
              value={email}
              onChangeText={(text) => setEmail(text)}
              onSubmitEditing={() => {
                passwordRef.focus();
              }}
              blurOnSubmit={false}
            />
            {emailError && (
              <HelperText type="error" visible={emailError}>
                {emailError}
              </HelperText>
            )}
            <TextInput
              label={i18n.t('password')}
              autoCompleteType="password"
              textContentType="password"
              secureTextEntry
              mode="outlined"
              value={password}
              onChangeText={(text) => setPassword(text)}
              ref={(input) => {
                passwordRef = input;
              }}
            />
            {(passwordError || authError) && (
              <HelperText type="error" visible={passwordError || authError}>
                {passwordError || authError}
              </HelperText>
            )}
            <Button
              onPress={loginPressed}
              loading={loading}
              mode="contained"
              style={styles.button}>
              {i18n.t('log_in')}
            </Button>
            <Button
              mode="contained"
              color={colors.accent}
              onPress={() => navigation.navigate('RegisterScreen')}
              style={styles.button}>
              {i18n.t('register')}
            </Button>
            <Button
              mode="text"
              onPress={() => navigation.navigate('PasswordEmailScreen')}
              style={styles.button}>
              {i18n.t('forgot_password')}
            </Button>
            {/* <Title >Social Login</Title> */}
            {/* <SocialLoginBar /> */}
          </Card.Content>
        </Card>
      </ScrollView>

      <HelperText style={styles.copyrightText} type="info">
        {expo.name} v{expo.version}{' '}
        {appEnv !== 'production' ? `@ ${appEnv}` : ''}
      </HelperText>
      <Snackbar
        visible={snackBarVisible}
        duration={3000}
        wrapperStyle={globalStyles.snackBar}
        onDismiss={() => setSnackBarVisible(false)}>
        Environment Switched to {appEnv}
      </Snackbar>
    </SafeAreaView>
    // </SafeAreaView>
  );
};

export const styles = StyleSheet.create({
  title: {
    marginBottom: 15,
  },
  copyrightText: {
    textAlign: 'center',
    fontSize: 8,
  },
  button: {
    marginTop: 15,
  },
});
