import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { LogInScreen } from '../screens/auth/LogInScreen';
import { AuthParamList } from '../types';
import { RegisterScreen } from '../screens/auth/RegisterScreen';
import { PasswordEmailScreen } from '../screens/auth/PasswordEmailScreen';
import { EmailSentScreen } from '../screens/auth/EmailSentScreen';

const AuthStack = createStackNavigator<AuthParamList>();

export default function AuthNavigator() {
  return (
    <AuthStack.Navigator
      screenOptions={{
        header: () => null,
      }}>
      <AuthStack.Screen name="LogInScreen" component={LogInScreen} />
      <AuthStack.Screen name="RegisterScreen" component={RegisterScreen} />
      <AuthStack.Screen
        name="PasswordEmailScreen"
        component={PasswordEmailScreen}
      />
      <AuthStack.Screen name="EmailSentScreen" component={EmailSentScreen} />
    </AuthStack.Navigator>
  );
}
