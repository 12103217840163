const defaultFonts = {
  regular: {
    fontFamily: 'Quicksand_500Medium',
    fontWeight: 'normal' as 'normal',
  },
  medium: {
    fontFamily: 'Quicksand_700Bold',
    fontWeight: 'normal' as 'normal',
  },
  light: {
    fontFamily: 'Quicksand_300Light',
    fontWeight: 'normal' as 'normal',
  },
  thin: {
    fontFamily: 'Quicksand_400Regular',
    fontWeight: 'normal' as 'normal',
  },
};

export const fontConfig = {
  web: defaultFonts,
  ios: defaultFonts,
  android: defaultFonts,
  default: defaultFonts,
};
