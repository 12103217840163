import React from 'react';
import {
  Modal,
  Text,
  Portal,
  ProgressBar,
  Title,
  Surface,
  useTheme,
} from 'react-native-paper';
import prettyBytes from 'pretty-bytes';
import { RootState } from '../redux/rootReducer';
import { useSelector } from 'react-redux';
import { StyleSheet } from 'react-native';
import { globalStyles } from '../constants/Styles';

export const UploadModal = () => {
  const { progress, status, file, progressBytes, totalBytes } = useSelector(
    (state: RootState) => state.uploads,
  );
  const theme = useTheme();

  const visible = status === 'started';

  return (
    <Portal>
      <Modal visible={visible}>
        <Surface style={[styles.card, { borderRadius: theme.roundness }]}>
          <Title style={globalStyles.textCenter}>Uploading</Title>
          {file && (
            <Text style={globalStyles.textCenter}>File: {file.name}</Text>
          )}
          <Text style={globalStyles.textCenter}>
            {prettyBytes(progressBytes)} / {prettyBytes(totalBytes)}
          </Text>
          <ProgressBar progress={progress} />
        </Surface>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  card: {
    margin: 20,
    padding: 20,
  },
});
