import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { FlatList, StyleSheet, View } from 'react-native';
import { Card, Text } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { globalStyles } from '../../constants/Styles';
import { getPosts, Post, postsSelector } from '../../redux/postsSlice';
import { RootState } from '../../redux/rootReducer';
import { AppDispatch } from '../../redux/store';
import { FormattedDate } from 'react-intl';

export function PostListScreen() {
  const navigation = useNavigation();
  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(getPosts({}));
    });
    return unsubscribe;
  }, [dispatch, navigation]);

  const posts = useSelector((state: RootState) => postsSelector(state));

  return (
    <View style={globalStyles.flex}>
      <FlatList
        data={posts}
        renderItem={({ item }) => (
          <PostItem
            post={item}
            onPress={() =>
              navigation.navigate('PostDetails', {
                postId: item.id,
                postTitle: item.name,
              })
            }
          />
        )}
        contentContainerStyle={styles.flatListContent}
        keyExtractor={(item, index) => `${index}`}
        onRefresh={() => dispatch(getPosts({}))}
        refreshing={false}
      />
    </View>
  );
}

const PostItem = ({ post, onPress }: { post: Post; onPress: () => void }) => {
  return (
    <Card style={styles.postCard} onPress={onPress}>
      <Card.Cover source={{ uri: post.picture }} />
      <Card.Title
        title={post.name}
        subtitle={
          <FormattedDate
            value={new Date(post.created_at)}
            dateStyle="full"
            timeStyle="short"
          />
        }
        subtitleStyle={styles.subtitle}
      />
      <Card.Content>
        <Text numberOfLines={3}>{post.content}</Text>
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  flatListContent: {
    paddingTop: 15,
  },
  postCard: {
    marginHorizontal: 15,
    marginBottom: 15,
  },
  subtitle: {
    textTransform: 'uppercase',
  },
});
