import { combineReducers } from 'redux';
import auth from './authSlice';
import app from './appSlice';
import uploads from './uploadsSlice';
import posts from './postsSlice';

const rootReducer = combineReducers({
  app,
  auth,
  uploads,
  posts,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
