import { StyleSheet } from 'react-native';

export const globalStyles = StyleSheet.create({
  flex: { flex: 1 },
  flexRow: { flexDirection: 'row' },
  basicContent: {
    flex: 1,
    justifyContent: 'center',
  },
  centerCard: {
    maxWidth: 600,
    width: '100%',
  },
  profileCard: {
    maxWidth: 600,
    width: '100%',
    marginTop: 60,
  },
  basicScrollContent: {
    padding: 15,
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scrollContentForNavBar: {
    padding: 15,
    alignItems: 'center',
  },
  logo: {
    resizeMode: 'contain',
    width: 100,
    height: 100,
    alignSelf: 'center',
    marginBottom: 50,
  },
  snackBar: {
    alignSelf: 'center',
  },
  leftIcon: {
    marginRight: 15,
  },
  textCenter: {
    textAlign: 'center',
  },
});
