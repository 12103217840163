import React, { useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { View, StyleSheet, ScrollView, Platform } from 'react-native';
import { Button, Card, TextInput, HelperText } from 'react-native-paper';
import i18n from 'i18n-js';
import { globalStyles } from '../../constants/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { HomeStackParamList } from '../../types';
import { get } from 'lodash';
import {
  clearRequests,
  updatePassword,
  UpdatePasswordParameters,
} from '../../redux/authSlice';
import { AppDispatch } from '../../redux/store';
import { useLinkTo } from '@react-navigation/native';

export default function PasswordEditScreen({
  navigation,
}: StackScreenProps<HomeStackParamList, 'PasswordEdit'>) {
  let newPasswordRef: any;
  let newPasswordConfRef: any;

  const dispatch: AppDispatch = useDispatch();
  const linkTo = useLinkTo();

  const { user, error, loading, requestSuccess } = useSelector(
    (state: RootState) => state.auth,
  );

  const [email] = useState(get(user, 'email', ''));
  const [password, setPassword] = useState('');
  const passwordError = get(error, 'errors.password[0]', false);
  const [newPassword, setNewPassword] = useState('');
  const newPasswordError = get(error, 'errors.new_password[0]', false);
  const [newPasswordConf, setNewPasswordConf] = useState('');
  const newPasswordConfError = get(
    error,
    'errors.new_password_confirmation[0]',
    false,
  );

  let authError = get(error, 'error', false);
  if (authError === 'Unauthorised') {
    authError =
      'Email and/or password have been rejected. Please check your credentials and try again.';
  }

  const submit = () => {
    let userData: UpdatePasswordParameters = {
      email: email,
      password: password,
      new_password: newPassword,
      new_password_confirmation: newPasswordConf,
    };
    dispatch(updatePassword(userData));
  };

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(clearRequests());
    });
    if (requestSuccess) {
      dispatch(clearRequests());
      navigation.goBack();
    }
    return unsubscribe;
  }, [dispatch, navigation, requestSuccess]);

  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.scrollContentForNavBar}>
        <Card style={globalStyles.profileCard}>
          <Card.Content>
            <TextInput
              label="Email"
              autoCompleteType="email"
              textContentType="emailAddress"
              mode="outlined"
              dense={true}
              disabled
              value={email}
            />
            <TextInput
              label={i18n.t('password')}
              autoCompleteType="password"
              textContentType="password"
              secureTextEntry
              mode="outlined"
              value={password}
              blurOnSubmit={false}
              onChangeText={(text) => setPassword(text)}
              onSubmitEditing={() => {
                newPasswordRef.focus();
              }}
            />
            {(passwordError || authError) && (
              <HelperText type="error" visible={passwordError || authError}>
                {passwordError || authError}
              </HelperText>
            )}
            <TextInput
              label={i18n.t('new_password')}
              autoCompleteType="password"
              textContentType="password"
              secureTextEntry
              mode="outlined"
              value={newPassword}
              blurOnSubmit={false}
              onChangeText={(text) => setNewPassword(text)}
              onSubmitEditing={() => {
                newPasswordConfRef.focus();
              }}
              ref={(input) => {
                newPasswordRef = input;
              }}
            />
            {(newPasswordError || authError) && (
              <HelperText type="error" visible={newPasswordError || authError}>
                {newPasswordError || authError}
              </HelperText>
            )}
            <TextInput
              label={i18n.t('new_password_confirmation')}
              autoCompleteType="password"
              textContentType="password"
              secureTextEntry
              mode="outlined"
              value={newPasswordConf}
              onChangeText={(text) => setNewPasswordConf(text)}
              ref={(input) => {
                newPasswordConfRef = input;
              }}
            />
            {(newPasswordConfError || authError) && (
              <HelperText
                type="error"
                visible={newPasswordConfError || authError}>
                {newPasswordConfError || authError}
              </HelperText>
            )}

            <Button
              style={styles.button}
              mode="contained"
              loading={loading}
              onPress={() => submit()}>
              {i18n.t('save')}
            </Button>
            <Button
              style={styles.button}
              mode="text"
              onPress={() =>
                Platform.OS === 'web'
                  ? linkTo('/profile/edit')
                  : navigation.goBack()
              }>
              {i18n.t('cancel')}
            </Button>
          </Card.Content>
        </Card>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: 15,
  },
});
