import React, { useState } from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import {
  View,
  StyleSheet,
  ScrollView,
  ImageBackground,
  Platform,
} from 'react-native';
import {
  Button,
  Title,
  Card,
  TextInput,
  HelperText,
  Surface,
  TouchableRipple,
  Portal,
  Modal,
  useTheme,
} from 'react-native-paper';
import i18n from 'i18n-js';
import { globalStyles } from '../../constants/Styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { HomeStackParamList } from '../../types';
import { get } from 'lodash';
import {
  clearRequests,
  updateMyUser,
  UpdateMyUserParameters,
} from '../../redux/authSlice';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { AppDispatch } from '../../redux/store';
import * as ImagePicker from 'expo-image-picker';
import { uploadFile } from '../../redux/uploadsSlice';
import * as FileSystem from 'expo-file-system';
import { useLinkTo } from '@react-navigation/native';

export default function ProfileEditScreen({
  navigation,
}: StackScreenProps<HomeStackParamList, 'ProfileEdit'>) {
  let lastNameRef: any;

  const dispatch: AppDispatch = useDispatch();
  const theme = useTheme();
  const linkTo = useLinkTo();

  const { user, error, loading, requestSuccess } = useSelector(
    (state: RootState) => state.auth,
  );

  const [email] = useState(get(user, 'email', ''));
  const [firstName, setFirstName] = useState(get(user, 'first_name', ''));
  const firstNameError = get(error, 'errors.first_name[0]', false);

  const [lastName, setLastName] = useState(get(user, 'last_name', ''));
  const lastNameError = get(error, 'errors.last_name[0]', false);
  const [modalVisible, setModalVisible] = React.useState(false);

  const { file, status } = useSelector((state: RootState) => state.uploads);

  let userPicture = user.thumbnail || undefined;
  if (status === 'completed' && file) {
    userPicture = file.url;
  }

  const submit = () => {
    let userData: UpdateMyUserParameters = {
      id: user.id,
      first_name: firstName,
      last_name: lastName,
    };
    if (file) {
      userData.files = [file.id];
    }
    dispatch(updateMyUser(userData));
  };

  const pickImage = async (source: 'camera' | 'library') => {
    setModalVisible(false);
    let result;
    if (source === 'library') {
      if (Platform.OS !== 'web') {
        const {
          status: permissionStatus,
        } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (permissionStatus !== 'granted') {
          // eslint-disable-next-line no-alert
          alert('Sorry, we need media library permissions to make this work!');
        }
      }

      result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        exif: true,
        quality: 1,
      });
    } else {
      if (Platform.OS !== 'web') {
        const {
          status: permissionStatus,
        } = await ImagePicker.requestCameraPermissionsAsync();
        if (permissionStatus !== 'granted') {
          // eslint-disable-next-line no-alert
          alert('Sorry, we need camera permissions to make this work!');
        }
      }
      result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        exif: true,
        quality: 1,
      });
    }

    if (!result.cancelled) {
      let localFile = await FileSystem.getInfoAsync(result.uri, { size: true });
      if (localFile.exists) {
        const uploadingFile = { path: result.uri, ...result };
        dispatch(
          uploadFile({
            name: 'profile.jpg',
            type: result.type || '',
            context: 'feature',
            size: localFile.size || 0,
            file: uploadingFile,
          }),
        );
      }
    }
  };

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(clearRequests());
    });
    if (requestSuccess) {
      dispatch(clearRequests());
      navigation.goBack();
    }
    return unsubscribe;
  }, [dispatch, navigation, requestSuccess]);

  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.scrollContentForNavBar}>
        <Card style={globalStyles.profileCard}>
          <Card.Content>
            <Surface style={styles.avatarContainer}>
              <ImageBackground
                source={{ uri: userPicture }}
                style={styles.avatarImgBg}>
                <TouchableRipple
                  onPress={() => setModalVisible(true)}
                  style={styles.avatarTouchContainer}>
                  <Icon name="pen" color={'white'} size={30} />
                </TouchableRipple>
              </ImageBackground>
            </Surface>

            <TextInput
              label="Email"
              autoCompleteType="email"
              textContentType="emailAddress"
              mode="outlined"
              dense={true}
              disabled
              value={email}
            />
            <TextInput
              label={i18n.t('first_name')}
              autoCompleteType="name"
              mode="outlined"
              dense={true}
              value={firstName}
              onChangeText={(text) => setFirstName(text)}
              onSubmitEditing={() => {
                lastNameRef.focus();
              }}
              blurOnSubmit={false}
            />
            {firstNameError && (
              <HelperText type="error" visible={firstNameError}>
                {firstNameError}
              </HelperText>
            )}
            <TextInput
              label={i18n.t('last_name')}
              autoCompleteType="name"
              mode="outlined"
              dense={true}
              value={lastName}
              onChangeText={(text) => setLastName(text)}
              ref={(input) => {
                lastNameRef = input;
              }}
            />
            {lastNameError && (
              <HelperText type="error" visible={lastNameError}>
                {lastNameError}
              </HelperText>
            )}

            <Button
              style={styles.button}
              mode="contained"
              loading={loading}
              onPress={() => submit()}>
              {i18n.t('save')}
            </Button>
            <Button
              style={styles.button}
              mode="text"
              onPress={() =>
                Platform.OS === 'web' ? linkTo('/profile') : navigation.goBack()
              }>
              {i18n.t('cancel')}
            </Button>
          </Card.Content>
        </Card>
        <Button
          style={styles.button}
          mode="text"
          onPress={() => navigation.navigate('PasswordEdit')}>
          {i18n.t('edit_password')}
        </Button>
      </ScrollView>
      <Portal>
        <Modal visible={modalVisible} onDismiss={() => setModalVisible(false)}>
          <Surface
            style={[styles.modalCard, { borderRadius: theme.roundness }]}>
            <Title style={globalStyles.textCenter}>
              {i18n.t('select_picture')}
            </Title>
            <Button mode="text" onPress={() => pickImage('camera')}>
              {i18n.t('camera')}
            </Button>
            <Button mode="text" onPress={() => pickImage('library')}>
              {i18n.t('gallery')}
            </Button>
          </Surface>
        </Modal>
      </Portal>
    </View>
  );
}

const styles = StyleSheet.create({
  button: {
    marginTop: 15,
  },
  avatarContainer: {
    elevation: 2,
    borderRadius: 50,
    height: 100,
    width: 100,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: -65,
  },
  avatarImgBg: {
    flex: 1,
    overflow: 'hidden',
    width: '100%',
    borderRadius: 50,
  },
  avatarTouchContainer: {
    flex: 1,
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#55555555',
  },
  modalCard: {
    margin: 20,
    padding: 20,
  },
});
