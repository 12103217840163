import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction,
  createSelector,
} from '@reduxjs/toolkit';
import * as ApiService from '../services/ApiService';
import { logoutSuccess, clearRequests } from './authSlice';
import { RootState } from './rootReducer';

export interface Post {
  id: number;
  created_at: string;
  updated_at: string;
  name: string;
  content: string;
  picture: string;
}

export const getPosts = createAsyncThunk<Post[], ApiService.ListParameters, {}>(
  'posts/get',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ApiService.listRequest({
        resource: 'posts',
        params: data,
      });
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export interface StorePostParameters {
  title: string;
  content: string;
  image: string;
}

export const storePost = createAsyncThunk<
  Post,
  StorePostParameters,
  { state: RootState; rejectValue: ApiService.ValidationErrors }
>('posts/store', async (data, { rejectWithValue }) => {
  try {
    const { title, content, image } = data;

    const params = {
      title,
      content,
      image,
    };
    const response = await ApiService.storeRequest({
      resource: 'posts',
      params,
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export interface UpdatePostParameters {
  id: string | number;
  title: string;
  content: string;
  image: string;
}

export const updatePost = createAsyncThunk<
  Post,
  UpdatePostParameters,
  { state: RootState; rejectValue: ApiService.ValidationErrors }
>('posts/update', async (data, { rejectWithValue }) => {
  try {
    const response = await ApiService.updateRequest({
      resource: 'posts',
      params: data,
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const deletePost = createAsyncThunk<
  Post,
  ApiService.DeleteParameters,
  {}
>('posts/delete', async (data, { rejectWithValue }) => {
  try {
    const response = await ApiService.deleteRequest({
      resource: 'posts',
      params: data,
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const postsAdapter = createEntityAdapter<Post>({
  sortComparer: (a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  },
});

interface CreatePostState extends ApiService.ListParameters {
  requestSuccess: boolean;
  loading: boolean;
  error?: ApiService.ValidationErrors;
  completed?: {
    id: string | number;
    postId: string | number;
    type: string;
  };
}

const initialState: CreatePostState = {
  loading: false,
  requestSuccess: false,
  error: undefined,
  completed: undefined,
};

const postsSlice = createSlice({
  name: 'posts',
  initialState: postsAdapter.getInitialState({
    ...initialState,
  }),
  reducers: {
    // clearNotification(state) {
    //   state.notification = undefined;
    //   return state;
    // },
  },
  extraReducers: (builder) => {
    //GET GOALS
    builder.addCase(getPosts.pending, (state, action) => {
      state.filter = action.meta.arg.filter;
      return state;
    });

    builder.addCase(
      getPosts.fulfilled,
      (state, action: PayloadAction<Post[]>) => {
        postsAdapter.upsertMany(state, action.payload);
      },
    );

    // STORE GOAL
    builder.addCase(storePost.pending, (state) => {
      state.loading = true;
      state.requestSuccess = false;
      state.error = undefined;
      return state;
    });
    builder.addCase(storePost.fulfilled, (state, action) => {
      postsAdapter.addOne(state, action.payload);
      state.loading = false;
      state.error = undefined;
      state.requestSuccess = true;
      // state.notification = 'Added to Diary';
      return state;
    });
    builder.addCase(storePost.rejected, (state, action) => {
      state.requestSuccess = false;
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
      return state;
    });

    // UPDATE GOAL
    builder.addCase(updatePost.pending, (state) => {
      state.loading = true;
      state.requestSuccess = false;
      state.error = undefined;
      return state;
    });
    builder.addCase(updatePost.fulfilled, (state, action) => {
      postsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
      state.loading = false;
      state.error = undefined;
      state.requestSuccess = true;
      // state.notification = 'Post Updated';
      state.completed = undefined;

      return state;
    });
    builder.addCase(updatePost.rejected, (state, action) => {
      state.requestSuccess = false;
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
      return state;
    });

    // DELETE GOAL
    builder.addCase(deletePost.pending, (state) => {
      state.loading = true;
      state.requestSuccess = false;
      state.error = undefined;
    });
    builder.addCase(deletePost.fulfilled, (state, action) => {
      postsAdapter.removeOne(state, action.payload.id);
      state.loading = false;
      state.error = undefined;
      state.requestSuccess = true;
      return state;
    });

    // GENERAL
    builder.addCase(logoutSuccess, (state) => {
      state = postsAdapter.getInitialState({
        ...initialState,
      });
      return state;
    });
    builder.addCase(clearRequests, (state) => {
      state.loading = false;
      state.requestSuccess = false;
      state.error = undefined;
      return state;
    });
  },
});

// export const { clearNotification } = postsSlice.actions;

export const postsSelector = createSelector(
  [
    (state: RootState) => postsAdapter.getSelectors().selectAll(state.posts),
    (state: RootState) => state.posts.filter,
  ],
  (posts, filter) => {
    if (filter && filter.date) {
      return posts.filter(
        (obj) =>
          new Date(obj.created_at) <= new Date(filter.date || '') &&
          new Date(obj.created_at) >= new Date(filter.date || ''),
      );
    }
    return posts;
  },
);

export default postsSlice.reducer;
