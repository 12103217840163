import axiosInstance from './axiosInstance';
import { AxiosError } from 'axios';
import { Platform } from 'react-native';
import { AppDispatch } from '../redux/store';
import { StorePostParameters, UpdatePostParameters } from '../redux/postsSlice';
import { StoreFileParameters } from '../redux/uploadsSlice';
import { SerializedError } from '@reduxjs/toolkit';
import {
  UpdateMyUserParameters,
  RegisterParameters,
  PasswordEmailParameters,
  LoginSocialParameters,
} from '../redux/authSlice';

export interface ValidationErrors extends SerializedError {
  errors?: Record<string, string[]>;
}

export const loginRequest = (email: String, password: String): Promise<any> => {
  return axiosInstance
    .post('login', {
      email,
      password,
    })
    .then(({ data }) => {
      const { token } = data.data;
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      return data.data;
    })
    .catch((err) => {
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

export const loginSocialRequest = (
  params: LoginSocialParameters,
): Promise<any> => {
  return axiosInstance
    .post('login/social', params)
    .then(({ data }) => {
      const { token } = data.data;
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      return data.data;
    })
    .catch((err) => {
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

export const refreshLoginRequest = (token: string): Promise<any> => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  //   console.log('refreshLogin', token);
  // TODO REFRESH_TOKEN => REPLACE WITH refesh token request
  return axiosInstance
    .get('posts')
    .then(() => {
      return true;
    })
    .catch((err) => {
      //   console.log('LOGIN ERROR', response);
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

export interface GetParameters {
  id: number;
}

export const getRequest = ({
  resource,
  params,
}: {
  resource: string;
  params: GetParameters;
}): Promise<any> => {
  return axiosInstance
    .get(`${resource}/${params.id}`)
    .then(({ data }) => {
      return data.data;
    })
    .catch((err) => {
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

export interface ListParameters {
  filter?: {
    id?: (string | number)[];
    category?: string;
    date?: string;
    intentions?: number[];
    topics?: number[];
    needs?: number[];
    acts?: number[];
    practices?: number[];
  };
}

export const listRequest = ({
  resource,
  params,
}: {
  resource: string;
  params: ListParameters;
}): Promise<any> => {
  let queryString = '';
  if (params && params.filter) {
    const { filter } = params;
    queryString = `?filter=${JSON.stringify(filter)}`;
  }
  return axiosInstance
    .get(`${resource}${queryString}`)
    .then(({ data }) => {
      if (resource === 'stats') {
        return data;
      }
      return data.data;
    })
    .catch((err) => {
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

export type StoreParameters =
  | StorePostParameters
  | StoreFileParameters
  | RegisterParameters
  | PasswordEmailParameters;

export const storeRequest = ({
  resource,
  params,
}: {
  resource: string;
  params: StoreParameters;
}): Promise<any> => {
  return axiosInstance
    .post(resource, params)
    .then(({ data }) => {
      if (resource === 'files') {
        return data;
      }
      if (resource === 'register') {
        const { token } = data.data;
        axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
      return data.data;
    })
    .catch((err) => {
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

export type UpdateParameters = UpdatePostParameters | UpdateMyUserParameters;

export const updateRequest = ({
  resource,
  params,
}: {
  resource: string;
  params: UpdateParameters;
}): Promise<any> => {
  let url = `${resource}`;
  if (params.id) {
    url += `/${params.id}`;
  }
  return axiosInstance
    .put(url, params)
    .then(({ data }) => {
      return data.data;
    })
    .catch((err) => {
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

export interface DeleteParameters {
  id: number | string;
}

export const deleteRequest = ({
  resource,
  params,
}: {
  resource: string;
  params: DeleteParameters;
}): Promise<any> => {
  return axiosInstance
    .delete(`${resource}/${params.id}`)
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      let error: AxiosError<ValidationErrors> = err;
      if (!error.response) {
        return Promise.reject(error);
      }
      return Promise.reject(error.response.data);
    });
};

//https://medium.com/@mouneyrac/s3-presigned-url-with-react-native-5a38dffd798c
export const s3Upload = async (
  uploadUrl: string,
  photo: any,
  dispatch: AppDispatch,
  uploadProgressAction: any,
) => {
  return new Promise((resolve, reject) => {
    const file = {
      name: photo.filename,
      type: photo.mime,
      uri:
        Platform.OS === 'android'
          ? photo.path
          : photo.path.replace('file://', ''),
    };

    const xhr = new XMLHttpRequest();
    xhr.open('PUT', uploadUrl);
    xhr.setRequestHeader('Content-Type', photo.mime);
    xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.onerror = function (e) {
      console.log('err', e);
    };
    xhr.upload.addEventListener('progress', (e) => {
      // handle notifications about upload progress: e.loaded / e.total
      //   console.log('progress', e);
      const progressPercent = e.loaded / e.total;
      dispatch(
        uploadProgressAction({
          progress: progressPercent,
          progressBytes: e.loaded,
        }),
      );
    });
    xhr.onreadystatechange = function () {
      if (xhr.readyState === xhr.DONE) {
        if (xhr.status === 200) {
          // Successfully uploaded the file.
          resolve('ok');
        } else {
          console.log('failed to upload presignedurl', xhr);
          reject('error');
        }
      }
    };
    xhr.send(file);
    // could be used to cancel the upload on user action
    // xhr.abort();
  });
};
