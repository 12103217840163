import React from 'react';
import { Image, StyleSheet, ScrollView, View } from 'react-native';
import { Card, Title, Button } from 'react-native-paper';
import { ParamListBase } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import { Text } from 'react-native-paper';
import i18n from 'i18n-js';

import { globalStyles } from '../../constants/Styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { logoImg } from '../../constants/Images';

export const EmailSentScreen = ({
  navigation,
}: {
  navigation: StackNavigationProp<ParamListBase>;
}) => {
  const { lastEmailReset } = useSelector((state: RootState) => state.auth);

  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.basicScrollContent}>
        <Image source={logoImg} style={globalStyles.logo} />
        <Card style={globalStyles.centerCard}>
          <Card.Content>
            <Title>{i18n.t('email_sent')}</Title>
            <Text style={styles.text}>{i18n.t('email_sent_description')}</Text>
            <Text style={styles.email}>{lastEmailReset}</Text>
            <Button
              mode="contained"
              onPress={() => navigation.popToTop()}
              style={styles.button}>
              {i18n.t('ok')}
            </Button>
          </Card.Content>
        </Card>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  text: {
    marginVertical: 10,
  },
  email: {
    textAlign: 'center',
    padding: 30,
  },
  button: {
    marginTop: 15,
  },
});
