import * as React from 'react';
import { View, ScrollView, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { RootState } from '../../redux/rootReducer';
import { Title, Text, useTheme, Card, ToggleButton } from 'react-native-paper';
import { globalStyles } from '../../constants/Styles';
import { setLanguage, setTheme } from '../../redux/appSlice';
import i18n from 'i18n-js';

export function SettingsScreen() {
  const dispatch: AppDispatch = useDispatch();
  const { theme, language } = useSelector((state: RootState) => state.app);
  const paperTheme = useTheme();

  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.scrollContentForNavBar}>
        <Card style={[globalStyles.centerCard, styles.card]}>
          <Card.Content>
            <Title>{i18n.t('theme')}</Title>
            <ToggleButton.Row
              onValueChange={(value) => value && dispatch(setTheme(value))}
              value={theme}>
              <ToggleButton
                icon={() => (
                  <View style={styles.iconText}>
                    <Icon
                      style={globalStyles.leftIcon}
                      color={paperTheme.colors.text}
                      name="white-balance-sunny"
                    />
                    <Text>{i18n.t('light')}</Text>
                  </View>
                )}
                value="light"
                style={globalStyles.flex}
              />
              <ToggleButton
                icon={() => (
                  <View style={styles.iconText}>
                    <Icon
                      style={globalStyles.leftIcon}
                      color={paperTheme.colors.text}
                      name="moon-waning-crescent"
                    />
                    <Text>{i18n.t('dark')}</Text>
                  </View>
                )}
                value="dark"
                style={globalStyles.flex}
              />
            </ToggleButton.Row>
          </Card.Content>
        </Card>

        <Card style={[globalStyles.centerCard, styles.card]}>
          <Card.Content>
            <Title>{i18n.t('language')}</Title>
            <ToggleButton.Row
              onValueChange={(value) => value && dispatch(setLanguage(value))}
              value={language}>
              <ToggleButton
                icon={() => (
                  <View style={styles.iconText}>
                    <Text>{i18n.t('english')}</Text>
                  </View>
                )}
                value="en"
                style={globalStyles.flex}
              />
              <ToggleButton
                icon={() => (
                  <View style={styles.iconText}>
                    <Text>{i18n.t('french')}</Text>
                  </View>
                )}
                value="fr"
                style={globalStyles.flex}
              />
            </ToggleButton.Row>
          </Card.Content>
        </Card>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  card: {
    marginBottom: 15,
  },
  iconText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
