import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as ApiService from '../services/ApiService';
import { RootState } from './rootReducer';
import { RESET_STATE } from '@redux-offline/redux-offline/lib/constants';
import { AppThunk } from './store';
import axiosInstance from '../services/axiosInstance';
import { dev, staging, Environment, production } from '../env';

interface AuthUser {
  id?: number;
  email?: string;
  token?: string;
  first_name?: string;
  last_name?: string;
  picture?: string;
  thumbnail?: string;
  country_residence?: string;
  age_group?: string;
  profession?: string;
  nationality?: string;
  gender?: string;
  social_provider?: string;
  social_id?: string;
}

export interface LoginParameters {
  email: string;
  password: string;
}
export const login = createAsyncThunk<
  AuthUser,
  LoginParameters,
  {
    rejectValue: ApiService.ValidationErrors;
  }
>('auth/login', async (data, { rejectWithValue }) => {
  try {
    const { email, password } = data;
    const response = await ApiService.loginRequest(email, password);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export interface LoginSocialParameters {
  provider: string;
  token?: string | null;
  social_id?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
}

export const loginSocial = createAsyncThunk<
  AuthUser,
  LoginSocialParameters,
  {
    rejectValue: ApiService.ValidationErrors;
  }
>('auth/login/social', async (data, { rejectWithValue }) => {
  try {
    const response = await ApiService.loginSocialRequest(data);
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const refreshLogin = createAsyncThunk<
  AuthUser,
  {},
  { state: RootState; rejectValue: ApiService.ValidationErrors }
>('auth/refreshLogin', async (data, { rejectWithValue, getState }) => {
  try {
    const { auth } = getState();
    switchAppEnv(auth.appEnv);
    if (auth.user.token) {
      const response = await ApiService.refreshLoginRequest(auth.user.token);
      return response;
    } else {
      throw 'Error refreshing token: User not logged in';
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const getMyUser = createAsyncThunk<
  AuthUser,
  {},
  { state: RootState; rejectValue: ApiService.ValidationErrors }
>('auth/getMyUser', async (data, { rejectWithValue, getState }) => {
  try {
    const { auth } = getState();
    if (auth.user.id) {
      const response = await ApiService.getRequest({
        resource: 'users',
        params: { id: auth.user.id },
      });
      return response;
    } else {
      throw 'Error refreshing token: User not logged in';
    }
  } catch (err) {
    return rejectWithValue(err);
  }
});

export interface UpdateMyUserParameters {
  id?: number;
  email?: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  picture?: string;
  country_residence?: string;
  age_group?: string;
  profession?: string;
  nationality?: string;
  gender?: string;
  files?: number[];
}
export const updateMyUser = createAsyncThunk<
  AuthUser,
  UpdateMyUserParameters,
  { rejectValue: ApiService.ValidationErrors }
>('auth/updateMyUser', async (data, { rejectWithValue }) => {
  try {
    const response = await ApiService.updateRequest({
      resource: 'users',
      params: data,
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export interface RegisterParameters {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
}
export const register = createAsyncThunk<
  AuthUser,
  RegisterParameters,
  { state: RootState; rejectValue: ApiService.ValidationErrors }
>('auth/register', async (data, { rejectWithValue }) => {
  try {
    const response = await ApiService.storeRequest({
      resource: 'register',
      params: data,
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export interface PasswordEmailParameters {
  email: string;
}

export const passwordEmail = createAsyncThunk<
  AuthUser,
  PasswordEmailParameters,
  {
    rejectValue: ApiService.ValidationErrors;
  }
>('auth/password/email', async (data, { rejectWithValue }) => {
  try {
    const response = await ApiService.storeRequest({
      resource: 'password/email',
      params: data,
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export interface UpdatePasswordParameters {
  email: string;
  password: string;
  new_password: string;
  new_password_confirmation: string;
}

export const updatePassword = createAsyncThunk<
  AuthUser,
  UpdatePasswordParameters,
  {
    rejectValue: ApiService.ValidationErrors;
  }
>('auth/password/update', async (data, { rejectWithValue }) => {
  try {
    const response = await ApiService.updateRequest({
      resource: 'password',
      params: data,
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});

const switchAppEnv = (env: Environment) => {
  switch (env) {
    case 'dev':
      axiosInstance.defaults.baseURL = dev.API_URL;
      break;
    case 'staging':
      axiosInstance.defaults.baseURL = staging.API_URL;
      break;
    case 'production':
      axiosInstance.defaults.baseURL = production.API_URL;
      break;
    default:
      break;
  }
};

interface AuthState {
  user: AuthUser;
  requestSuccess: boolean;
  loading: boolean;
  error?: ApiService.ValidationErrors;
  userHasSeenInstructions: boolean;
  lastEmailReset: string;
  appEnv: Environment;
}

const initialState: AuthState = {
  user: {
    // id: undefined,
    // email: undefined,
    // token: undefined,
  },
  loading: false,
  requestSuccess: false,
  error: undefined,
  userHasSeenInstructions: false,
  lastEmailReset: '',
  appEnv: 'production',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    instructionsSeen(state) {
      state.userHasSeenInstructions = true;
      return state;
    },
    logoutSuccess(state) {
      state = { ...initialState, appEnv: state.appEnv };
      return state;
    },
    clearRequests(state) {
      state.loading = false;
      state.requestSuccess = false;
      state.error = undefined;
      return state;
    },
    switchEnvironment(state, action) {
      const { env } = action.payload;
      state.appEnv = env;
      switchAppEnv(action.payload.env);
      return state;
    },
  },
  extraReducers: (builder) => {
    // LOGIN
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.userHasSeenInstructions = false;
      state.loading = false;
      state.error = undefined;
      state.user = payload;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    });

    // LOGIN SOCIAL
    builder.addCase(loginSocial.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(loginSocial.fulfilled, (state, { payload }) => {
      state.userHasSeenInstructions = false;
      state.loading = false;
      state.error = undefined;
      state.user = payload;
    });
    builder.addCase(loginSocial.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    });

    // REGISTER
    builder.addCase(register.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.user = payload;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    });

    // GET MY USER
    builder.addCase(getMyUser.fulfilled, (state, { payload }) => {
      Object.assign(state.user, payload);
    });

    // UPDATE MY USER
    builder.addCase(updateMyUser.pending, (state) => {
      state.loading = true;
      state.error = undefined;
    });
    builder.addCase(updateMyUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = undefined;
      state.requestSuccess = true;
      Object.assign(state.user, payload);
    });
    builder.addCase(updateMyUser.rejected, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    });

    // PASSWORD EMAIL
    builder.addCase(passwordEmail.pending, (state) => {
      state.loading = true;
      state.requestSuccess = false;
      state.error = undefined;
    });
    builder.addCase(passwordEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.lastEmailReset = action.meta.arg.email.trim().toLowerCase();
      state.error = undefined;
      state.requestSuccess = true;
    });
    builder.addCase(passwordEmail.rejected, (state, action) => {
      state.requestSuccess = false;
      state.loading = false;
      if (action.payload) {
        if (action.payload.message === 'Unable to send reset link') {
          state.error = {
            ...action.payload,
            errors: {
              email: ['This email address is not registered with us.'],
            },
          };
        } else {
          state.error = action.payload;
        }
      } else {
        state.error = action.error;
      }
    });

    // UPDATE PASSWORD
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true;
      state.requestSuccess = false;
      state.error = undefined;
    });
    builder.addCase(updatePassword.fulfilled, (state) => {
      state.loading = false;
      state.error = undefined;
      state.requestSuccess = true;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.requestSuccess = false;
      state.loading = false;
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    });
  },
});

export const {
  logoutSuccess,
  clearRequests,
  instructionsSeen,
  switchEnvironment,
} = authSlice.actions;

export const logout = (): AppThunk => async (dispatch) => {
  dispatch({ type: RESET_STATE });
  dispatch(logoutSuccess());
};

export default authSlice.reducer;
