import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Provider, useSelector } from 'react-redux';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import useCachedResources from './hooks/useCachedResources';
// import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
import store from './redux/store';
import {
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
  Provider as PaperProvider,
} from 'react-native-paper';
import {
  DarkTheme as NavigationDarkTheme,
  DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import CustomTheme from './constants/Themes';
// const translations = require('constants/Translations.json');
import translations from './constants/Translations.json';
import { RootState } from './redux/rootReducer';
import { UploadModal } from './components/UploadModal';
import { IntlProvider } from 'react-intl';
import 'intl';
import { Platform } from 'react-native';

if (Platform.OS === 'android') {
  // See https://github.com/expo/expo/issues/6536 for this issue.
  if (typeof (Intl as any).__disableRegExpRestore === 'function') {
    (Intl as any).__disableRegExpRestore();
  }
}

// Set the key-value pairs for the different languages you want to support.
i18n.translations = translations;
i18n.fallbacks = true;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale; //'en' // 'fr

export default function App() {
  const isLoadingComplete = useCachedResources();

  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <Provider store={store}>
        <ThemeWrapper />
      </Provider>
    );
  }
}

function ThemeWrapper() {
  const { theme: stateTheme, language } = useSelector(
    (state: RootState) => state.app,
  );

  i18n.locale = language;

  const CombinedDefaultTheme = {
    ...PaperDefaultTheme,
    ...NavigationDefaultTheme,
    ...CustomTheme.light,
    colors: {
      ...PaperDefaultTheme.colors,
      ...NavigationDefaultTheme.colors,
      ...CustomTheme.light.colors,
    },
  };
  const CombinedDarkTheme = {
    ...PaperDarkTheme,
    ...NavigationDarkTheme,
    ...CustomTheme.dark,
    colors: {
      ...PaperDarkTheme.colors,
      ...NavigationDarkTheme.colors,
      ...CustomTheme.dark.colors,
    },
  };

  const theme =
    stateTheme === 'light' ? CombinedDefaultTheme : CombinedDarkTheme;

  return (
    <PaperProvider theme={theme}>
      <IntlProvider locale={language} defaultLocale="en">
        <SafeAreaProvider>
          <Navigation theme={theme} />
          <StatusBar style={stateTheme === 'light' ? 'dark' : 'light'} />
          <UploadModal />
        </SafeAreaProvider>
      </IntlProvider>
    </PaperProvider>
  );
}
