import React from 'react';
import { StackScreenProps } from '@react-navigation/stack';
import { View, Image, StyleSheet } from 'react-native';
import { Button, Title, Card, Text } from 'react-native-paper';
import i18n from 'i18n-js';
import { RootStackParamList } from '../types';
import { globalStyles } from '../constants/Styles';
import { logoImg } from '../constants/Images';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';

export default function NotFoundScreen({
  navigation,
}: StackScreenProps<RootStackParamList, 'NotFound'>) {
  const { user } = useSelector((state: RootState) => state.auth);

  return (
    <View style={styles.center}>
      <Image source={logoImg} style={[globalStyles.logo]} />
      <Card style={globalStyles.centerCard}>
        <Card.Content>
          <Title style={styles.title}>{i18n.t('404_not_found')}</Title>
          <Text>{i18n.t('404_text')}</Text>
          <Button
            onPress={() =>
              navigation.replace(user.token ? 'HomeStack' : 'Auth')
            }
            mode="contained"
            style={styles.button}>
            {i18n.t('home')}
          </Button>
        </Card.Content>
      </Card>
    </View>
  );
}

const styles = StyleSheet.create({
  center: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
  },
});
