import { configureFonts } from 'react-native-paper';
import { fontConfig } from './Fonts';

const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  light: {
    dark: false,
    mode: 'adaptive' as 'adaptive',
    roundness: 5,
    colors: {
      primary: '#303f9f',
      accent: '#512da8',
      background: '#fff',
      surface: '#eee',
      error: '#ba000d',
      text: '#000',
      disabled: '#999',
      placeholder: '#444',
      backdrop: '#000000aa',
      onBackground: '#000',
      onSurface: '#000',
      notification: 'red',
    },

    fonts: configureFonts(fontConfig),

    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    dark: true,
    // adaptive is better but was causing a crash when tapping a card and then changing theme to dark
    mode: 'exact' as 'exact',
    roundness: 5,
    colors: {
      primary: '#666ad1',
      accent: '#8559da',
      background: '#222',
      surface: '#333',
      error: '#dc000d',
      text: '#fff',
      disabled: '#777',
      placeholder: '#bbb',
      backdrop: '#000000aa',
      onBackground: '#FFFFFF',
      onSurface: '#FFFFFF',
      notification: 'red',
    },

    fonts: configureFonts(fontConfig),

    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};
