import * as React from 'react';
import { ScrollView, View } from 'react-native';
import { Card, Text, Title } from 'react-native-paper';
import { globalStyles } from '../../constants/Styles';

export function NotificationsScreen({ navigation }) {
  return (
    <View style={globalStyles.flex}>
      <ScrollView
        keyboardShouldPersistTaps="handled"
        contentContainerStyle={globalStyles.scrollContentForNavBar}>
        <Card style={globalStyles.centerCard}>
          <Card.Content>
            <Title>Notifications</Title>
            <Text>Where are they?</Text>
          </Card.Content>
        </Card>
      </ScrollView>
    </View>
  );
}
