import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import * as ApiService from '../services/ApiService';
import {logoutSuccess, clearRequests} from './authSlice';
import {AppDispatch} from './store';

interface ApiFile {
  name: string;
  type: string;
  size: number;
  context: string;
  url: string;
  id: number;
}

interface UploadState {
  file: ApiFile | undefined;
  uploadUrl: string | undefined;
  status: string | 'idle' | 'started' | 'completed' | 'failed';
  progress: number;
  progressBytes: number;
  totalBytes: number;
}

export interface StoreFileParameters {
  name: string;
  context: string;
  type: string;
  size: number;
  file: any;
}

export const uploadFile = createAsyncThunk<
  UploadState,
  StoreFileParameters,
  {rejectValue: ApiService.ValidationErrors; dispatch: AppDispatch}
>('uploads/file', async (data, {rejectWithValue, dispatch}) => {
  try {
    const {size, file} = data;

    const response = await ApiService.storeRequest({
      resource: 'files',
      params: data,
    });

    dispatch(addFile({file: response.file}));
    await ApiService.s3Upload(
      response.uploadUrl,
      file,
      dispatch,
      uploadProgress,
    );

    return {
      file: response.file,
      uploadUrl: response.uploadUrl,
      status: 'completed',
      progress: 100,
      progressBytes: size,
      totalBytes: size,
    };
  } catch (err) {
    return rejectWithValue(err);
  }
});

const initialState: UploadState = {
  file: undefined,
  uploadUrl: undefined,
  status: 'idle',
  progress: 0,
  progressBytes: 0,
  totalBytes: 1,
};

const uploadsSlice = createSlice({
  name: 'uploads',
  initialState: initialState,
  reducers: {
    cancelUpload(state) {
      state = initialState;
      return state;
    },
    uploadProgress(state, action) {
      state.progress = action.payload.progress;
      state.progressBytes = action.payload.progressBytes;
      return state;
    },
    addFile(state, action) {
      state.file = action.payload.file;
      state.status = 'started';
      state.totalBytes = action.payload.file.size;
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(uploadFile.pending, state => {
      state = initialState;
      return state;
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.file = action.payload.file;
      state.uploadUrl = action.payload.uploadUrl;
      state.status = action.payload.status;
      state.progress = action.payload.progress;
      state.progressBytes = action.payload.progressBytes;
      return state;
    });
    builder.addCase(uploadFile.rejected, state => {
      state = initialState;
      state.status = 'failed';
      return state;
    });
    builder.addCase(logoutSuccess, state => {
      state = initialState;
      return state;
    });
    builder.addCase(clearRequests, state => {
      state = initialState;
      return state;
    });
  },
});

export const {uploadProgress, addFile} = uploadsSlice.actions;

export default uploadsSlice.reducer;
