import { configureStore, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import reducer, { RootState } from './rootReducer';
import { offline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { refreshLogin } from './authSlice';

const middleware = [...getDefaultMiddleware()];

// import { createLogger } from 'redux-logger';
// if (__DEV__) {
//   const reduxLogger = createLogger({
//     duration: true,
//     timestamp: true,
//     level: 'log',
//     logErrors: true,
//     diff: true,
//   });
//   middleware.push(reduxLogger);
// }

const appOfflineConfig = {
  ...offlineConfig,
  persistOptions: {
    blacklist: ['uploads'],
  },
  persistCallback: () => {
    store.dispatch(refreshLogin({}));
  },
};

const store = configureStore({
  reducer,
  middleware,
  enhancers: (defaultEnhancers) => [
    offline(appOfflineConfig),
    ...defaultEnhancers,
  ],
  devTools: !!__DEV__,
});

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
