import { RouteProp, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Image, ScrollView, StyleSheet, View } from 'react-native';
import { Caption, Paragraph, Surface, Title } from 'react-native-paper';
import { useDispatch, useSelector } from 'react-redux';
import { globalStyles } from '../../constants/Styles';
import { getPosts, Post } from '../../redux/postsSlice';
import { RootState } from '../../redux/rootReducer';
import { AppDispatch } from '../../redux/store';
import { HomeStackParamList } from '../../types';
import { FormattedDate } from 'react-intl';

export function PostDetailsScreen({
  route,
}: {
  route: RouteProp<HomeStackParamList, 'PostDetails'>;
}) {
  const navigation = useNavigation();
  const dispatch: AppDispatch = useDispatch();

  const { postId } = route.params;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      // componentDidMount
      dispatch(getPosts({ filter: { id: [postId] } }));
    });
    return unsubscribe;
  }, [dispatch, navigation, postId]);

  const { entities } = useSelector((state: RootState) => state.posts);
  const post = entities[postId];

  if (!post) {
    return null;
  }

  return (
    <View style={globalStyles.flex}>
      <ScrollView keyboardShouldPersistTaps="handled">
        <PostItem post={post} />
      </ScrollView>
    </View>
  );
}

const PostItem = ({ post }: { post: Post }) => {
  return (
    <Surface>
      <Image source={{ uri: post.picture }} style={styles.image} />
      <View style={styles.content}>
        <Title>{post.name}</Title>
        <Caption style={styles.subtitle}>
          <FormattedDate
            value={new Date(post.created_at)}
            dateStyle="full"
            timeStyle="short"
          />
        </Caption>
        <Paragraph>{post.content}</Paragraph>
      </View>
    </Surface>
  );
};

const styles = StyleSheet.create({
  image: { height: 300, resizeMode: 'cover', width: '100%' },
  content: {
    padding: 20,
  },
  subtitle: {
    textTransform: 'uppercase',
  },
});
